@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.action-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  .v-input {
    max-width: 400px;
  }
}
::v-deep .col-device-id {
  width: 25%;
}

::v-deep .col-type {
  width: 10%;
}

::v-deep .col-version {
  width: 18%;
}

::v-deep .col-ds {
  width: 10%;
}

::v-deep .col-pu {
  width: 37%;
}

::v-deep .col-actions {
  width: 5%;
  text-align: right !important;
}

::v-deep td:last-child:not(:first-child) {
  text-align: right !important;
}
